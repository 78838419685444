import { useTranslation } from 'react-i18next'
import {
  DimensionUnit,
  ManageMaterial,
  MaterialQuality,
  MaterialState,
  MaterialType,
  TermsOfSale,
  Unit,
  QuantityType,
  EstimatedAge,
  Assembly,
  computeResourceQuantities,
  CerfaWaste,
  parseRaeDimensionString,
} from '../../models/materials.models'
import { RetrievalModality } from '../../models/commons.models'
import { ItemType, SelectOption } from '../../models/props.models'
import { IconButton } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { useCallback, useMemo, useState } from 'react'
import { ObjectUtils } from '../../utils/commons.utils'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import dayjs from '../../utils/dayjs.utils'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import LoaderOverlay from '../layout/LoaderOverlay.layout'
import { sessionService } from '../../store/session'
import Table from '../common/Table.common'
import ModalFormMaterial from './ModalForm.material'
import ExcelJS from 'exceljs'
import { FileUtils } from '../../utils/files.utils'
import { usePapaParse } from 'react-papaparse'
import constants from '../../constants'
import {
  checkCategory,
  findCategory,
  getCategoryChoices,
  ResourceCategory2Category,
} from '../../models/categories.models'
import { useObservable } from '@ngneat/react-rxjs'
import { carbonModelsQuery } from '../../store/carbonModels'

const getColName = (index: number): string => {
  if (index < 0) {
    return ''
  }
  const colNames = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const res = []

  let current = index

  let currentPow = 1
  let total = Math.pow(colNames.length, currentPow)
  while (current > total - 1) {
    currentPow++
    total += Math.pow(colNames.length, currentPow)
  }

  while (currentPow !== 0) {
    total = current
    for (let i = 1; i < currentPow; i++) {
      total -= Math.pow(colNames.length, i)
    }
    const colIndex = Math.floor(total / Math.pow(colNames.length, currentPow - 1))
    current = current - (colIndex + 1) * Math.pow(colNames.length, currentPow - 1)
    res.push(colNames[colIndex])
    currentPow = currentPow - 1
  }

  return res.join('')
}

interface ModalImportProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'tabs'> {
  type: MaterialType
  useImperials: boolean
  showCerfa: boolean
}

type MaterialRow = Partial<ManageMaterial> & { hasError?: boolean; imagePath?: string }
interface Attribute {
  key: string
  label?: string
  severalCol?: boolean
  required?: boolean
  multiLine?: string
  instruction?: boolean | string
  type?: any
  enumPath?: string
  hasError?: (material: MaterialRow, multiLineIndex?: number) => boolean
}

const getResourcesAttributes = ({
  showCerfa,
  showPublicNeeds,
  type,
}: {
  showCerfa: boolean
  showPublicNeeds: boolean
  type: MaterialType
}): Attribute[] => [
  {
    key: 'primaryCategory',
    label: 'materials:attributes.primaryCategory',
    required: true,
    type: 'category',
  },
  {
    key: 'secondaryCategory',
    required: true,
    type: 'category',
    label: 'materials:attributes.secondaryCategory',
  },
  {
    key: 'tertiaryCategory',
    required: true,
    label: 'materials:attributes.tertiaryCategory',
    type: 'category',
  },
  {
    key: 'imagePath',
    instruction: 'imagePath',
    label: 'materials:components.modalImport.imagePath',
  },
  {
    key: 'name',
    required: true,
    instruction: 'name',
    label: 'materials:attributes.name',
  },
  {
    key: 'unit',
    required: true,
    label: 'materials:attributes.unit',
    type: Unit,
    enumPath: 'materials:unitSymbol',
  },
  ...(type === MaterialType.resource && showCerfa
    ? [
        {
          key: 'totalQty',
          instruction: 'totalQty',
          label: 'materials:components.modalImport.totalQty',
          type: 'positiv',
        },
      ]
    : []),
  {
    key: 'initialQty',
    instruction: 'initialQty',
    label:
      type === MaterialType.need
        ? 'materials:attributes.desiredQty'
        : 'materials:components.modalImport.initialQty',
    type: 'positiv',
  },
  ...(type === MaterialType.resource
    ? [
        {
          key: 'currentQty',
          label: 'materials:components.modalImport.currentQty',
          type: 'positiv',
        },
      ]
    : []),
  {
    key: 'quality',
    label:
      type === MaterialType.need
        ? 'materials:attributes.minimumQuality'
        : 'materials:attributes.globalQuality',
    type: MaterialQuality,
    enumPath: 'materials:quality',
  },
  ...(type === MaterialType.need
    ? [
        {
          key: 'uniqueDeposit',
          label: 'materials:attributes.uniqueDeposit',
          type: 'boolean',
        },
      ]
    : []),
  {
    key: 'description',
    label:
      type === MaterialType.need
        ? 'materials:attributes.observations'
        : 'materials:attributes.description',
  },
  ...(type === MaterialType.resource
    ? [
        {
          key: 'privateDescription',
          label: 'materials:attributes.privateDescription',
        },
      ]
    : []),
  ...(type === MaterialType.resource
    ? [
        {
          key: 'dimensions.unit',
          label: 'materials:components.modalImport.dimensionsUnit',
          type: DimensionUnit,
          enumPath: 'materials:dimensionUnitSymbol',
        },
        {
          key: 'dimensions.length',
          label: 'materials:attributes.dimensions.length',
          type: 'positiv',
        },
        {
          key: 'dimensions.width',
          label: 'materials:attributes.dimensions.width',
          type: 'positiv',
        },
        {
          key: 'dimensions.height',
          label: 'materials:attributes.dimensions.height',
          type: 'positiv',
        },
        {
          key: 'dimensions.diameter',
          label: 'materials:attributes.dimensions.diameter',
          type: 'positiv',
        },
        {
          key: 'technicalDetails',
          label: 'materials:attributes.technicalDetails.title',
          severalCol: true,
          instruction: 'technicalDetails',
          type: 'split',
        },
        {
          key: 'technicalDetails',
          label: 'materials:attributes.technicalDetails.title',
          severalCol: true,
          instruction: 'technicalDetails',
          type: 'split',
        },
        {
          key: 'technicalDetails',
          label: 'materials:attributes.technicalDetails.title',
          severalCol: true,
          instruction: 'technicalDetails',
          type: 'split',
        },
        {
          key: 'technicalDetails',
          label: 'materials:attributes.technicalDetails.title',
          severalCol: true,
          instruction: 'technicalDetails',
          type: 'split',
        },
        {
          key: 'technicalDetails',
          label: 'materials:attributes.technicalDetails.title',
          severalCol: true,
          instruction: 'technicalDetails',
          type: 'split',
        },
        {
          key: 'unitWeight',
          label: 'materials:attributes.weight.unit',
          type: 'positiv',
        },
        {
          key: 'density',
          label: 'materials:attributes.density',
          type: 'positiv',
        },
        {
          key: 'carbonModel',
          label: 'materials:attributes.carbon.carbonModel',
          type: 'carbonModels',
        },
        {
          key: 'productionCarbon',
          label: 'materials:attributes.carbon.productionCarbon',
          type: 'positiv',
        },
        {
          key: 'endOfLifeCarbon',
          label: 'materials:attributes.carbon.endOfLifeCarbon',
          type: 'positiv',
        },
        {
          key: 'tracksOfReuse',
          label: 'materials:attributes.carbon.tracksOfReuse',
        },
        {
          key: 'sellByQuantityOf',
          label: 'materials:attributes.sell.sellByQuantityOf',
          type: 'positiv',
        },
        {
          key: 'minQuantity',
          label: 'materials:attributes.sell.minQuantity',
          type: 'positiv',
          hasError: (material: MaterialRow) => {
            return (
              !!material.sellByQuantityOf &&
              !!material.minQuantity &&
              material.minQuantity % material.sellByQuantityOf !== 0
            )
          },
        },
        {
          key: 'termsOfSale',
          label: 'materials:attributes.sell.termsOfSale',
          type: TermsOfSale,
          enumPath: 'materials:termsOfSale',
        },
        {
          key: 'price',
          label: 'materials:attributes.sell.price',
          type: 'positiv',
          hasError: (material: MaterialRow) => {
            return material.termsOfSale === TermsOfSale.sale && !material.price
          },
        },
        {
          key: 'retrieval.fromDefault',
          label: 'materials:attributes.retrieval.fromDefault',
          type: 'boolean',
        },
        {
          key: 'state',
          label: 'materials:attributes.state',
          type: MaterialState,
          enumPath: 'materials:state',
        },
      ]
    : []),
  ...(type === MaterialType.resource
    ? [
        {
          key: 'retrieval.startDate',
          label: 'materials:components.modalImport.startDate',
          type: 'date',
        },
      ]
    : []),
  {
    key: 'retrieval.endDate',
    label:
      type === MaterialType.need
        ? 'materials:attributes.needsEndDate'
        : 'materials:components.modalImport.endDate',
    type: 'date',
  },
  ...(type === MaterialType.resource
    ? [
        {
          key: 'retrieval.retrievalModality',
          label: 'materials:attributes.retrieval.modality',
          type: RetrievalModality,
          enumPath: 'global:retrievalModality',
        },
        {
          key: 'conditioning',
          label: 'materials:attributes.conditioning',
        },
      ]
    : []),
  ...(showPublicNeeds || type === MaterialType.resource
    ? [
        {
          key: 'visible',
          label: 'materials:attributes.visible',
          type: 'boolean',
        },
      ]
    : []),
  ...(type === MaterialType.resource && showCerfa
    ? [
        {
          key: 'wasteOnly',
          label: 'materials:attributes.wasteOnly',
          type: 'boolean',
        },
        {
          key: 'cerfaResource.primaryCategory',
          label: 'materials:attributes.cerfaResource.primaryCategory',
          type: 'resourceCategory',
          instruction: 'resourcePrimaryCategory',
        },
        {
          key: 'cerfaResource.secondaryCategory',
          label: 'materials:attributes.cerfaResource.secondaryCategory',
          type: 'resourceCategory',
          instruction: 'resourceSecondaryCategory',
        },
        {
          key: 'cerfaResource.tertiaryCategory',
          label: 'materials:attributes.cerfaResource.tertiaryCategory',
          type: 'resourceCategory',
          instruction: 'resourceTertiaryCategory',
        },
        {
          key: 'cerfaResource.constituentMaterials',
          label: 'materials:attributes.cerfaResource.constituentMaterials',
          type: 'slashSplit',
        },
        {
          key: 'cerfaResource.estimatedAge',
          label: 'materials:attributes.cerfaResource.estimatedAge',
          type: EstimatedAge,
          enumPath: 'materials:estimatedAge',
        },
        {
          key: 'cerfaResource.assembly',
          label: 'materials:attributes.cerfaResource.assembly',
          type: Assembly,
          enumPath: 'materials:assembly',
        },
        {
          key: 'cerfaResource.hazardousSuspiction',
          label: 'materials:attributes.cerfaResource.hazardousSuspiction',
          type: 'boolean',
        },
        {
          key: 'cerfaResource.localized',
          label: 'materials:attributes.cerfaResource.localized',
          type: 'boolean',
        },
        {
          key: 'cerfaResource.hasReuseCondition',
          label: 'materials:attributes.cerfaResource.hasReuseCondition',
          type: 'boolean',
        },
        {
          key: 'cerfaResource.hasTechnicalDetails',
          label: 'materials:attributes.cerfaResource.hasTechnicalDetails',
          type: 'boolean',
        },
        {
          key: 'cerfaResource.hasPrecautions',
          label: 'materials:attributes.cerfaResource.hasPrecautions',
          type: 'boolean',
        },
        {
          key: 'primaryCategory',
          instruction: 'wastePrimaryCategory',
          label: 'materials:attributes.cerfaWaste.primaryCategory',
          type: 'wasteCategory',
          multiLine: 'cerfaWaste',
          required: true,
        },
        {
          key: 'secondaryCategory',
          label: 'materials:attributes.cerfaWaste.secondaryCategory',
          instruction: 'wasteSecondaryCategory',
          type: 'wasteCategory',
          multiLine: 'cerfaWaste',
        },
        {
          key: 'tertiaryCategory',
          label: 'materials:attributes.cerfaWaste.tertiaryCategory',
          instruction: 'wasteTertiaryCategory',
          type: 'wasteCategory',
          multiLine: 'cerfaWaste',
        },
        {
          key: 'wasteCode',
          label: 'materials:attributes.cerfaWaste.wasteCode',
          multiLine: 'cerfaWaste',
        },
        {
          key: 'reusePercent',
          label: 'materials:attributes.cerfaWaste.reusePercent',
          type: 'percent',
          multiLine: 'cerfaWaste',
        },
        {
          key: 'recyclablePercent',
          label: 'materials:attributes.cerfaWaste.recyclablePercent',
          type: 'percent',
          multiLine: 'cerfaWaste',
        },
        {
          key: 'backfillingPercent',
          label: 'materials:attributes.cerfaWaste.backfillingPercent',
          type: 'percent',
          multiLine: 'cerfaWaste',
        },
        {
          key: 'incineratedWithEnergyPercent',
          label: 'materials:attributes.cerfaWaste.incineratedWithEnergyPercent',
          type: 'percent',
          multiLine: 'cerfaWaste',
        },
        {
          key: 'incineratedWithoutEnergyPercent',
          label: 'materials:attributes.cerfaWaste.incineratedWithoutEnergyPercent',
          type: 'percent',
          multiLine: 'cerfaWaste',
        },
        {
          key: 'nonRecoverablePercent',
          label: 'materials:attributes.cerfaWaste.nonRecoverablePercent',
          type: 'percent',
          multiLine: 'cerfaWaste',
        },
        {
          key: 'hasConditions',
          label: 'materials:attributes.cerfaWaste.hasConditions',
          type: 'boolean',
          multiLine: 'cerfaWaste',
        },
        {
          key: 'wasteStreamsOutlets',
          label: 'materials:attributes.cerfaWaste.wasteStreamsOutlets',
          type: 'boolean',
          multiLine: 'cerfaWaste',
        },
      ]
    : []),
]
const pemAttributes = [
  'category',
  'quantity',
  'unit',
  'quality',
  'dimensions',
  'assembly',
  'estimatedAge',
  'hazardousSuspiction',
  'localized',
  'hasReuseCondition',
  'hasTechnicalDetails',
  'name',
  'hasPrecautions',
  'constituentMaterials',
]
// same on server materialService
const raeToPem = {
  unit: {
    // set: 'Unité', => prefer u than set
    m2: 'm2',
    lm: 'ml',
    m3: 'm3',
    u: 'Unité',
    t: 'Tonne',
  },
  quality: {
    new: 1,
    good: 2,
    slightlyDamaged: 3,
    damaged: 4,
  },
  assembly: {
    permanentChemical: 1,
    reversibleChemical: 2,
    mechanical: 3,
    byGravity: 4,
  },
  estimatedAge: {
    inferior2: 1,
    inferior10: 2,
    inferior50: 3,
    superior50: 4,
  },
}

const ModalImport: React.FC<ModalImportProps> = (props) => {
  const { t } = useTranslation()
  const show = useSnackbar()

  const { type, useImperials, showCerfa, ...formProps } = props
  const { readString } = usePapaParse()
  const [loading, setLoading] = useState<boolean>(false)
  const computedShowCerfa = useMemo(
    () => showCerfa && type === MaterialType.resource,
    [type, showCerfa],
  )
  const [carbonModels] = useObservable(carbonModelsQuery.carbonModels)

  const attributes = useMemo(() => {
    return getResourcesAttributes({
      showPublicNeeds: sessionService.showPublicNeeds(),
      showCerfa: computedShowCerfa,
      type,
    })
  }, [type, computedShowCerfa])

  const [value, setValue] = useState<{
    fileType: 'rae' | 'pem'
    file?: File
    imagesFolder?: any
    materials: MaterialRow[]
  }>({
    fileType: 'rae',
    materials: [],
  })
  const [indexOpen, setIndexOpen] = useState<number>(-1)
  const hasError = useCallback(
    (material: MaterialRow) => {
      return (
        (type === MaterialType.resource && !material.wasteOnly && !material.mainImageFileFile) ||
        attributes.some((attribute: Attribute) => {
          const checkError = (value: any, multiLineIndex?: number) => {
            if (attribute.hasError?.(material, multiLineIndex)) {
              return true
            }

            if (attribute.required && value === undefined) {
              return true
            }

            if (attribute.type === 'positiv' || attribute.type === 'percent') {
              if (
                value !== undefined &&
                (typeof value !== 'number' ||
                  value < 0 ||
                  (attribute.type === 'percent' && value > 100))
              ) {
                return true
              }
            }

            return false
          }
          if (attribute.multiLine) {
            let valueArray = ObjectUtils.getKeyValue(material, attribute.multiLine) ?? []
            for (let i = 0; i < valueArray.length; i++) {
              if (checkError(ObjectUtils.getKeyValue(valueArray[i], attribute.key), i)) {
                return true
              }
            }
            return false
          }
          return checkError(ObjectUtils.getKeyValue(material, attribute.key))
        })
      )
    },
    [attributes, type],
  )
  const loadData = useCallback(
    (file: File) => {
      setValue((val) => ({ ...val, file }))

      const materials: MaterialRow[] = []
      const colMap: string[] = []
      const importRow = (materialRow: any[]) => {
        try {
          let colMapReferenceIndex = colMap.findIndex(
            (key: string) =>
              t('materials:attributes.reference').toLowerCase().replace(/\*/gi, '').trim() === key,
          )
          const reference =
            colMapReferenceIndex !== -1
              ? (materialRow[colMapReferenceIndex] + '').trim()
              : undefined
          let multiLineIndex = -1
          const quantities = {
            totalQty: 0,
            initialQty: 0,
            currentQty: 0,
          }
          const materialIndex = materials.findIndex((material: MaterialRow) => {
            return reference && material.reference === reference
          })

          let material: MaterialRow =
            materialIndex !== -1
              ? materials[materialIndex]
              : {
                  _id: `local${Math.random().toString().split('.')[1]}`,
                  reference,
                  mainImageFileFile: undefined,
                  termsOfSale: TermsOfSale.notDefined,
                  visible: true,
                  wasteOnly: false,
                  retrieval: {
                    fromDefault: true,
                  } as any,
                  sellByQuantityOf: 1,
                  minQuantity: 0,
                  cerfaResource: {},
                  cerfaWaste: [],
                  type,
                }

          if (value.fileType === 'rae') {
            colMap.forEach((key: string, colMapIndex) => {
              let attribute = attributes.find(
                (attribute) =>
                  t(attribute.label as any)
                    .toLowerCase()
                    .replace(/\*/gi, '')
                    .trim() === (attribute.severalCol ? key.replace(/#.*/gi, '') : key),
              )
              if (attribute) {
                let value: any
                if (!attribute.type || typeof materialRow[colMapIndex] === 'string') {
                  value = (materialRow[colMapIndex] + '').trim()
                } else {
                  value = materialRow[colMapIndex]
                }
                if (value === '') {
                  value = undefined
                }

                if (attribute.type === 'slashSplit') {
                  value = value?.split(/\//).filter((val: string) => !!val) ?? []
                } else if (attribute.type === 'split') {
                  const splitted = typeof value === 'string' ? value.split(':') : undefined
                  value = splitted
                    ? { name: splitted[0]?.trim(), value: splitted[1]?.trim() }
                    : undefined
                  if (value && !value.name) {
                    value = undefined
                  }
                } else if (attribute.type === 'date') {
                  const date =
                    typeof value === 'string'
                      ? dayjs(value, dayjs().localeData().longDateFormat('L')).toDate()
                      : new Date(value)
                  value = date.toString() === 'Invalid Date' ? undefined : date.toISOString()
                } else if (attribute.type === 'positiv' || attribute.type === 'percent') {
                  value = Number.isNaN(Number(value)) ? undefined : Number(value)
                } else if (attribute.type === 'resourceCategory') {
                  const resourceCategories =
                    attribute.key === 'cerfaResource.primaryCategory'
                      ? getCategoryChoices('resource')
                      : attribute.key === 'cerfaResource.secondaryCategory'
                      ? getCategoryChoices('resource', material.cerfaResource?.primaryCategory)
                      : getCategoryChoices('resource', material.cerfaResource?.secondaryCategory)
                  const name = typeof value === 'string' ? value.toLowerCase() : undefined
                  value = resourceCategories.find(
                    (resourceCategory: SelectOption) =>
                      resourceCategory.label.toLowerCase().trim() === name,
                  )?.value
                } else if (attribute.type === 'wasteCategory') {
                  // cerfa waste index !
                  const wasteCategories =
                    attribute.key === 'primaryCategory'
                      ? getCategoryChoices('waste')
                      : attribute.key === 'secondaryCategory'
                      ? getCategoryChoices(
                          'waste',
                          material.cerfaWaste?.[multiLineIndex]?.primaryCategory,
                        )
                      : getCategoryChoices(
                          'waste',
                          material.cerfaWaste?.[multiLineIndex]?.secondaryCategory,
                        )

                  const name = typeof value === 'string' ? value.toLowerCase() : undefined
                  value = wasteCategories.find(
                    (wasteCategory: SelectOption) =>
                      wasteCategory.label.toLowerCase().trim() === name,
                  )?.value
                } else if (attribute.type === 'category') {
                  const categories =
                    attribute.key === 'primaryCategory'
                      ? getCategoryChoices('rae')
                      : attribute.key === 'secondaryCategory'
                      ? getCategoryChoices('rae', material.primaryCategory)
                      : getCategoryChoices('rae', material.secondaryCategory)
                  const name = typeof value === 'string' ? value.toLowerCase() : undefined
                  value = categories.find(
                    (category: SelectOption) => category.label.toLowerCase().trim() === name,
                  )?.value
                } else if (attribute.type === 'boolean') {
                  value =
                    value === t('materials:components.modalImport.boolean.true')
                      ? true
                      : value === t('materials:components.modalImport.boolean.false')
                      ? false
                      : undefined
                } else if (attribute.type === 'carbonModels') {
                  const carbonModel = carbonModels.find(
                    (carbonModel) =>
                      carbonModel.name.toLowerCase().trim() === value.toLowerCase().trim(),
                  )

                  value = carbonModel
                    ? {
                        label: carbonModel.name,
                        value: carbonModel._id,
                      }
                    : undefined
                } else if (attribute.type) {
                  const valueLabel = typeof value === 'string' ? value.toLowerCase() : undefined
                  const val = createOptionsFromEnum(
                    attribute.type,
                    attribute.enumPath as string,
                  ).find((val) => val.label.toLowerCase().trim() === valueLabel)
                  value = val?.value
                }

                if (value !== undefined) {
                  if (
                    attribute.key === 'totalQty' ||
                    attribute.key === 'initialQty' ||
                    attribute.key === 'currentQty'
                  ) {
                    quantities[attribute.key] = value ?? 0
                  } else if (attribute.multiLine) {
                    let materialValue = ObjectUtils.getKeyValue(material, attribute.multiLine) ?? []
                    if (multiLineIndex === -1) {
                      materialValue.push({})
                    }
                    multiLineIndex = materialValue.length - 1
                    materialValue[multiLineIndex] = ObjectUtils.setKeyValue(
                      materialValue[multiLineIndex],
                      attribute.key,
                      value,
                    )

                    material = ObjectUtils.setKeyValue(material, attribute.multiLine, materialValue)
                  } else if (materialIndex === -1) {
                    if (attribute.severalCol) {
                      const materialValue = ObjectUtils.getKeyValue(material, attribute.key) ?? []
                      materialValue.push(value)
                      material = ObjectUtils.setKeyValue(material, attribute.key, materialValue)
                    } else {
                      material = ObjectUtils.setKeyValue(material, attribute.key, value)
                    }
                  }
                }
              }
            })
          } else {
            pemAttributes.forEach((pemAttribute, index) => {
              let value = (materialRow[index] + '').trim()

              switch (pemAttribute) {
                case 'category':
                  material = {
                    ...material,
                    ...((ResourceCategory2Category as any)[Number(value)] ?? {}),
                    cerfaResource: {
                      ...material.cerfaResource,
                      ...findCategory('resource', Number(value)),
                    },
                  }
                  break
                case 'quantity':
                  quantities.initialQty = Number.isNaN(Number(value)) ? 0 : Number(value)
                  quantities.totalQty = quantities.initialQty
                  quantities.currentQty = quantities.initialQty
                  break
                case 'unit':
                  material.unit = Object.keys(raeToPem.unit).find(
                    (raeKey: string) =>
                      (raeToPem.unit as any)[raeKey].toLowerCase().replace(/\*/gi, '').trim() ===
                      value.toLowerCase().replace(/\*/gi, '').trim(),
                  ) as Unit | undefined
                  break
                case 'quality':
                  material.quality = Object.keys(raeToPem.quality).find(
                    (raeKey: string) => (raeToPem.quality as any)[raeKey] === Number(value),
                  ) as MaterialQuality | undefined
                  break
                case 'assembly':
                case 'estimatedAge':
                  material.cerfaResource![pemAttribute] = Object.keys(raeToPem[pemAttribute]).find(
                    (raeKey: string) =>
                      (raeToPem[pemAttribute as keyof typeof raeToPem] as any)[raeKey] ===
                      Number(value),
                  ) as any
                  break
                case 'dimensions':
                  material.dimensions = parseRaeDimensionString(value)
                  break
                case 'hazardousSuspiction':
                case 'localized':
                case 'hasReuseCondition':
                case 'hasTechnicalDetails':
                case 'hasPrecautions':
                  material.cerfaResource![pemAttribute] = value === 'true'
                  break
                case 'name':
                  material.name = value
                  break
                case 'constituentMaterials':
                  material.cerfaResource!.constituentMaterials = value.split(',')
                  break
              }
            })
          }

          material.quantities = []
          if (type === MaterialType.resource) {
            if (
              (quantities.totalQty && quantities.totalQty !== 0) ||
              (quantities.initialQty && quantities.initialQty !== 0) ||
              (quantities.currentQty && quantities.currentQty !== 0)
            ) {
              if (!material.quantities) {
                material.quantities = []
              }
              if (quantities.initialQty && quantities.initialQty !== 0) {
                material.quantities!.push({
                  _id: `local${Math.random().toString().split('.')[1]}`,
                  initial: true,
                  reusable: true,
                  type: QuantityType.others,
                  quantity: quantities.initialQty,
                  cerfaWaste: multiLineIndex !== -1 ? multiLineIndex : undefined,
                  quality: material.quality ?? MaterialQuality.slightlyDamaged,
                })
              }
              if (quantities.totalQty && quantities.totalQty > (quantities.initialQty ?? 0)) {
                material.quantities!.push({
                  _id: `local${Math.random().toString().split('.')[1]}`,
                  initial: true,
                  reusable: false,
                  type: QuantityType.others,
                  cerfaWaste: multiLineIndex !== -1 ? multiLineIndex : undefined,
                  quantity: quantities.totalQty - (quantities.initialQty ?? 0),
                  quality: material.quality ?? MaterialQuality.slightlyDamaged,
                })
              }
              if (quantities.currentQty && quantities.currentQty !== quantities.initialQty) {
                material.quantities!.push({
                  _id: `local${Math.random().toString().split('.')[1]}`,
                  initial: false,
                  type: QuantityType.others,
                  quantity: quantities.currentQty - (quantities.initialQty ?? 0),
                  quality: material.quality ?? MaterialQuality.slightlyDamaged,
                })
              }
            }
          }

          // remove (error will be handle with primaryCategory required)
          if (material.cerfaWaste) {
            material.cerfaWaste.forEach((cerfaWaste: CerfaWaste) => {
              if (
                !checkCategory(
                  'waste',
                  cerfaWaste.primaryCategory,
                  cerfaWaste.secondaryCategory,
                  cerfaWaste.tertiaryCategory,
                )
              ) {
                cerfaWaste.primaryCategory = undefined
                cerfaWaste.secondaryCategory = undefined
                cerfaWaste.tertiaryCategory = undefined
              }
            })
          }
          if (
            material.cerfaResource &&
            !checkCategory(
              'resource',
              material.cerfaResource.primaryCategory,
              material.cerfaResource.secondaryCategory,
              material.cerfaResource.tertiaryCategory,
            )
          ) {
            material.cerfaResource.primaryCategory = undefined
            material.cerfaResource.secondaryCategory = undefined
            material.cerfaResource.tertiaryCategory = undefined
          }
          if (
            !checkCategory(
              'rae',
              material.primaryCategory,
              material.secondaryCategory,
              material.tertiaryCategory,
            )
          ) {
            material.primaryCategory = undefined
            material.secondaryCategory = undefined
            material.tertiaryCategory = undefined
          }

          material.hasError = hasError(material)
          if (materialIndex === -1) {
            materials.push({
              ...material,
              ...computeResourceQuantities(material.quantities ?? []),
            })
          } else {
            materials[materialIndex] = {
              ...material,
              ...computeResourceQuantities(materials[materialIndex]?.quantities ?? []),
            }
          }
        } catch (err: any) {
          show(err)
        }
      }

      const importCsv = async () => {
        setLoading(true)
        var reader = new FileReader()

        reader.onload = async function (e: any) {
          readString(e.target.result, {
            worker: true,
            encoding: 'utf8',
            complete: ({ data }: { data: string[][] }) => {
              for (let i = 0; i < data.length; i++) {
                if (i === 0) {
                  data[0].forEach((col) => {
                    if (typeof col === 'string') {
                      colMap.push(col?.toLowerCase()?.trim().replace(/\*/gi, ''))
                    }
                  })
                } else {
                  importRow(data[i])
                }
              }

              setValue((val: any) => ({
                ...val,
                materials: materials.map((material: MaterialRow) => ({
                  ...material,
                  reference: undefined,
                })),
              }))
              setLoading(false)
            },
          })
        }

        reader.onerror = (err: any) => {
          show(err)
          setLoading(false)
        }
        reader.readAsText(file)
      }
      const importXlsx = async () => {
        setLoading(true)
        var reader = new FileReader()
        reader.onload = async function (e: any) {
          const workbook = new ExcelJS.Workbook()
          await workbook.xlsx.load(e.target.result)
          const sheet = workbook.getWorksheet(1)
          if (!sheet) {
            return
          }

          sheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            if (rowNumber === 1) {
              row.eachCell({ includeEmpty: true }, (cell) => {
                colMap.push(
                  typeof cell.value === 'string'
                    ? cell.value.toLowerCase().trim().replace(/\*/gi, '')
                    : '',
                )
              })
            } else if (rowNumber > 1) {
              const materialRow: any[] = []
              let hasValue = false
              row.eachCell({ includeEmpty: true }, (cell) => {
                materialRow.push(cell.value)
                if (cell.value) {
                  hasValue = true
                }
              })
              if (hasValue) {
                importRow(materialRow)
              }
            }
          })
          setValue((val: any) => ({ ...val, materials }))
          setLoading(false)
        }
        reader.onerror = (err: any) => {
          show(err)
          setLoading(false)
        }
        reader.readAsArrayBuffer(file)
      }

      if (file.type === 'text/csv') {
        importCsv()
      } else {
        importXlsx()
      }
    },
    [carbonModels, value.fileType, show, readString, attributes, t, type, hasError],
  )

  const loadDirectory = useCallback(
    (files: File[]) => {
      setValue((val: any) => ({
        ...val,
        materials: val.materials.map((material: MaterialRow) => {
          const res = {
            ...material,
            mainImageFileFile:
              material.mainImageFileFile ??
              files.find(
                (file: File) =>
                  file.name.trim().toLocaleLowerCase() ===
                  material.imagePath?.trim().toLocaleLowerCase(),
              ),
          }
          res.hasError = hasError(res)
          return res
        }),
      }))
    },
    [hasError],
  )

  const downloadFormat = useCallback(async () => {
    setLoading(true)
    try {
      const workbook = new ExcelJS.Workbook()

      const primaryCategoryCol = getColName(
        attributes.findIndex(
          (attribute) => attribute.key === 'primaryCategory' && !attribute.multiLine,
        ),
      )
      const secondaryCategoryCol = getColName(
        attributes.findIndex(
          (attribute) => attribute.key === 'secondaryCategory' && !attribute.multiLine,
        ),
      )
      const resourcePrimaryCategoryCol = getColName(
        attributes.findIndex((attribute) => attribute.key === 'cerfaResource.primaryCategory'),
      )
      const resourceSecondaryCategoryCol = getColName(
        attributes.findIndex((attribute) => attribute.key === 'cerfaResource.secondaryCategory'),
      )
      const wastePrimaryCategoryCol = getColName(
        attributes.findIndex(
          (attribute) =>
            attribute.key === 'primaryCategory' && attribute.multiLine === 'cerfaWaste',
        ),
      )
      const wasteSecondaryCategoryCol = getColName(
        attributes.findIndex(
          (attribute) =>
            attribute.key === 'secondaryCategory' && attribute.multiLine === 'cerfaWaste',
        ),
      )

      const sheetName = t('materials:components.modalImport.sheetName')
      const cleanNameName = t('materials:components.modalImport.cleanNameUtils')
      const secondaryUtilsName = t('materials:components.modalImport.secondaryUtilsSheetName')
      const tertiaryUtilsName = t('materials:components.modalImport.tertiaryUtilsSheetName')
      const secondaryResourceUtilsName = t(
        'materials:components.modalImport.secondaryResourceUtilsName',
      )
      const tertiaryResourceUtilsName = t(
        'materials:components.modalImport.tertiaryResourceUtilsName',
      )
      const secondaryWasteUtilsName = t('materials:components.modalImport.secondaryWasteUtilsName')
      const tertiaryWasteUtilsName = t('materials:components.modalImport.tertiaryWasteUtilsName')

      const importSheet = workbook.addWorksheet(sheetName)
      const instructionsSheet = workbook.addWorksheet(
        t('materials:components.modalImport.instructions.sheetName'),
      )
      const categoriesSheet = workbook.addWorksheet(
        t('materials:components.modalImport.categorySheetName'),
      )
      const cleanNameUtilsSheet = workbook.addWorksheet(cleanNameName)
      cleanNameUtilsSheet.state = 'hidden'
      const secondaryUtilsSheet = workbook.addWorksheet(secondaryUtilsName)
      secondaryUtilsSheet.state = 'hidden'
      const tertiaryUtilsSheet = workbook.addWorksheet(tertiaryUtilsName)
      tertiaryUtilsSheet.state = 'hidden'
      let resourceCategoriesSheet: ExcelJS.Worksheet,
        secondaryResourceUtilsSheet: ExcelJS.Worksheet,
        tertiaryResourceUtilsSheet: ExcelJS.Worksheet,
        wasteCategoriesSheet: ExcelJS.Worksheet,
        secondaryWasteUtilsSheet: ExcelJS.Worksheet,
        tertiaryWasteUtilsSheet: ExcelJS.Worksheet
      if (computedShowCerfa) {
        resourceCategoriesSheet = workbook.addWorksheet(
          t('materials:components.modalImport.resourceCategorySheetName'),
        )
        resourceCategoriesSheet.state = 'hidden'
        secondaryResourceUtilsSheet = workbook.addWorksheet(secondaryResourceUtilsName)
        secondaryResourceUtilsSheet.state = 'hidden'
        tertiaryResourceUtilsSheet = workbook.addWorksheet(tertiaryResourceUtilsName)
        tertiaryResourceUtilsSheet.state = 'hidden'
        wasteCategoriesSheet = workbook.addWorksheet(
          t('materials:components.modalImport.wasteCategorySheetName'),
        )
        wasteCategoriesSheet.state = 'hidden'
        secondaryWasteUtilsSheet = workbook.addWorksheet(secondaryWasteUtilsName)
        secondaryWasteUtilsSheet.state = 'hidden'
        tertiaryWasteUtilsSheet = workbook.addWorksheet(tertiaryWasteUtilsName)
        tertiaryWasteUtilsSheet.state = 'hidden'
      }

      const secondaryUtilsCol = secondaryUtilsSheet.getColumn(1)
      secondaryUtilsCol.values = [...Array.from({ length: 100 }, () => '')]
      secondaryUtilsCol.eachCell({ includeEmpty: true }, function (cell, rowNumber: number) {
        cell.value = {
          formula: `TRANSPOSE(INDIRECT(VLOOKUP('${sheetName}'!$${primaryCategoryCol}${
            rowNumber + 1
          },'${cleanNameName}'!$A$1:$B$999,2,)))`,
          result: '',
        }
      })

      const tertiaryUtilsCol = tertiaryUtilsSheet.getColumn(1)
      tertiaryUtilsCol.values = [...Array.from({ length: 100 }, () => '')]
      tertiaryUtilsCol.eachCell({ includeEmpty: true }, function (cell, rowNumber: number) {
        cell.value = {
          result: '',
          formula: `TRANSPOSE(INDIRECT(VLOOKUP('${sheetName}'!$${primaryCategoryCol}${
            rowNumber + 1
          }&'${sheetName}'!$${secondaryCategoryCol}${
            rowNumber + 1
          },'${cleanNameName}'!$A$1:$B$999,2,)))`,
        }
      })

      if (computedShowCerfa) {
        const secondaryResourceUtilsCol = secondaryResourceUtilsSheet!.getColumn(1)
        secondaryResourceUtilsCol.values = [...Array.from({ length: 100 }, () => '')]
        secondaryResourceUtilsCol.eachCell(
          { includeEmpty: true },
          function (cell, rowNumber: number) {
            cell.value = {
              formula: `TRANSPOSE(INDIRECT(VLOOKUP('${sheetName}'!$${resourcePrimaryCategoryCol}${
                rowNumber + 1
              },'${cleanNameName}'!$C$1:$D$999,2,)))`,
              result: '',
            }
          },
        )
        const tertiaryResourceUtilsCol = tertiaryResourceUtilsSheet!.getColumn(1)
        tertiaryResourceUtilsCol.values = [...Array.from({ length: 100 }, () => '')]
        tertiaryResourceUtilsCol.eachCell(
          { includeEmpty: true },
          function (cell, rowNumber: number) {
            cell.value = {
              result: '',
              formula: `TRANSPOSE(INDIRECT(VLOOKUP('${sheetName}'!$${resourcePrimaryCategoryCol}${
                rowNumber + 1
              }&'${sheetName}'!$${resourceSecondaryCategoryCol}${
                rowNumber + 1
              },'${cleanNameName}'!$C$1:$D$999,2,)))`,
            }
          },
        )

        const secondaryWasteUtilsCol = secondaryWasteUtilsSheet!.getColumn(1)
        secondaryWasteUtilsCol.values = [...Array.from({ length: 100 }, () => '')]
        secondaryWasteUtilsCol.eachCell({ includeEmpty: true }, function (cell, rowNumber: number) {
          cell.value = {
            formula: `TRANSPOSE(INDIRECT(VLOOKUP('${sheetName}'!$${wastePrimaryCategoryCol}${
              rowNumber + 1
            },'${cleanNameName}'!$E$1:$F$999,2,)))`,
            result: '',
          }
        })

        const tertiaryWasteUtilsCol = tertiaryWasteUtilsSheet!.getColumn(1)
        tertiaryWasteUtilsCol.values = [...Array.from({ length: 100 }, () => '')]
        tertiaryWasteUtilsCol.eachCell({ includeEmpty: true }, function (cell, rowNumber: number) {
          cell.value = {
            result: '',
            formula: `TRANSPOSE(INDIRECT(VLOOKUP('${sheetName}'!$${wastePrimaryCategoryCol}${
              rowNumber + 1
            }&'${sheetName}'!$${wasteSecondaryCategoryCol}${
              rowNumber + 1
            },'${cleanNameName}'!$E$1:$F$999,2,)))`,
          }
        })
      }

      let instructionRowIndex = 1
      instructionsSheet.columns = [{ key: 'attribute' }]
      instructionsSheet.getColumn('attribute').font = { bold: true }
      const title = instructionsSheet.getRow(instructionRowIndex++)
      title.values = [t('materials:components.modalImport.instructions.title')]

      const version = instructionsSheet.getRow(instructionRowIndex++)
      version.values = [t('materials:components.modalImport.instructions.version')]
      version.getCell(1).font = { italic: true }

      instructionRowIndex++
      // TODO get ride of @ in excel file and make it work all like Googlsheet
      const googleSheet = instructionsSheet.getRow(instructionRowIndex++)
      googleSheet.values = [
        computedShowCerfa
          ? t('materials:components.modalImport.instructions.googleSheetWithCerfa', {
              secondaryUtilsName,
              tertiaryUtilsName,
              secondaryResourceUtilsName,
              tertiaryResourceUtilsName,
              secondaryWasteUtilsName,
              tertiaryWasteUtilsName,
            })
          : t('materials:components.modalImport.instructions.googleSheet', {
              secondaryUtilsName,
              tertiaryUtilsName,
            }),
      ]
      googleSheet.getCell(1).font = { color: { argb: '00dd2a2a' } }

      const required = instructionsSheet.getRow(instructionRowIndex++)
      required.values = [t('materials:components.modalImport.instructions.required')]
      required.getCell(1).font = { color: { argb: '00f79646' } }
      instructionRowIndex++
      let categoriesExample: string[]
      let resourceCategoriesExample: string[]
      let wasteCategoriesExample: string[]

      categoriesSheet.columns = [
        { key: 'header' },
        { key: 'primaryCategory' },
        { key: 'secondaryCategory' },
        { key: 'tertiaryCategory' },
      ]
      let header = categoriesSheet.getRow(1)
      header.values = [
        t('materials:attributes.primaryCategory'),
        t('materials:attributes.secondaryCategory'),
        t('materials:attributes.tertiaryCategory'),
      ]
      let rowIndex = 2
      let cleanNameRow = 1

      // need all primary category along side :
      getCategoryChoices('rae').forEach((primaryOption: SelectOption) => {
        const primaryCleanRow = cleanNameUtilsSheet.getRow(cleanNameRow++)
        primaryCleanRow.values = [primaryOption.label, 'name' + primaryOption.value]
      })

      getCategoryChoices('rae').forEach((primaryOption: SelectOption) => {
        const children = getCategoryChoices('rae', primaryOption.value)
        if (children.length === 0) {
          const row = categoriesSheet.getRow(rowIndex)
          row.values = [primaryOption.label]
          rowIndex++
        } else {
          children.forEach((secondaryOption: SelectOption) => {
            const subChildren = getCategoryChoices('rae', secondaryOption.value)
            if (subChildren.length === 0) {
              const row = categoriesSheet.getRow(rowIndex)
              row.values = [primaryOption.label, secondaryOption.label]
              row.getCell(2).name = 'name' + primaryOption.value
              rowIndex++
            } else {
              const secondaryCleanRow = cleanNameUtilsSheet.getRow(cleanNameRow++)
              secondaryCleanRow.values = [
                primaryOption.label + secondaryOption.label,
                'name' + secondaryOption.value,
              ]
              subChildren.forEach((tertiaryOption: SelectOption) => {
                if (!categoriesExample) {
                  categoriesExample = [
                    primaryOption.label,
                    secondaryOption.label,
                    tertiaryOption.label,
                  ]
                }
                const row = categoriesSheet.getRow(rowIndex)
                row.values = [primaryOption.label, secondaryOption.label, tertiaryOption.label]
                row.getCell(2).name = 'name' + primaryOption.value
                row.getCell(3).name = 'name' + secondaryOption.value
                rowIndex++
              })
            }
          })
        }
      })

      if (computedShowCerfa) {
        resourceCategoriesSheet!.columns = [
          { key: 'header' },
          { key: 'primaryCategory' },
          { key: 'secondaryCategory' },
        ]
        header = resourceCategoriesSheet!.getRow(1)
        header.values = [
          t('materials:attributes.cerfaResource.primaryCategory'),
          t('materials:attributes.cerfaResource.secondaryCategory'),
        ]
        wasteCategoriesSheet!.columns = [
          { key: 'header' },
          { key: 'primaryCategory' },
          { key: 'secondaryCategory' },
          { key: 'tertiaryCategory' },
        ]
        header = wasteCategoriesSheet!.getRow(1)
        header.values = [
          t('materials:attributes.cerfaWaste.primaryCategory'),
          t('materials:attributes.cerfaWaste.secondaryCategory'),
          t('materials:attributes.cerfaWaste.tertiaryCategory'),
        ]

        const fillArray = (array: any[], length: number) => {
          const res = array.filter((value) => !!value)
          for (let i = 0; i < length - array.length; i++) {
            res.push('')
          }
          return res
        }

        rowIndex = 2
        cleanNameRow = 1
        getCategoryChoices('resource').forEach((primaryOption: SelectOption) => {
          const primaryCleanRow = cleanNameUtilsSheet.getRow(cleanNameRow++)
          primaryCleanRow.values = [
            // must be on col C and D
            ...fillArray(primaryCleanRow.values as any[], 2),
            primaryOption.label,
            'resourceName' + primaryOption.value,
          ]
        })
        getCategoryChoices('resource').forEach((primaryOption: SelectOption) => {
          const children = getCategoryChoices('resource', primaryOption.value)
          if (children.length === 0) {
            const row = resourceCategoriesSheet.getRow(rowIndex)
            row.values = [primaryOption.label]
            rowIndex++
          } else {
            children.forEach((secondaryOption: SelectOption) => {
              const subChildren = getCategoryChoices('resource', secondaryOption.value)
              if (subChildren.length === 0) {
                const row = resourceCategoriesSheet.getRow(rowIndex)
                row.values = [primaryOption.label, secondaryOption.label]
                row.getCell(2).name = 'resourceName' + primaryOption.value
                rowIndex++
              } else {
                const secondaryCleanRow = cleanNameUtilsSheet.getRow(cleanNameRow++)
                secondaryCleanRow.values = [
                  // must be on col C and D
                  ...fillArray(secondaryCleanRow.values as any[], 2),
                  primaryOption.label + secondaryOption.label,
                  'resourceName' + secondaryOption.value,
                ]

                subChildren.forEach((tertiaryOption: SelectOption) => {
                  if (!resourceCategoriesExample) {
                    resourceCategoriesExample = [
                      primaryOption.label,
                      secondaryOption.label,
                      tertiaryOption.label,
                    ]
                  }

                  const row = resourceCategoriesSheet.getRow(rowIndex)
                  row.values = [primaryOption.label, secondaryOption.label, tertiaryOption.label]
                  row.getCell(2).name = 'resourceName' + primaryOption.value
                  row.getCell(3).name = 'resourceName' + secondaryOption.value
                  rowIndex++
                })
              }
            })
          }
        })

        rowIndex = 2
        cleanNameRow = 1
        getCategoryChoices('waste').forEach((primaryOption: SelectOption) => {
          const primaryCleanRow = cleanNameUtilsSheet.getRow(cleanNameRow++)
          primaryCleanRow.values = [
            // must be on col E and F
            ...fillArray(primaryCleanRow.values as any[], 4),
            primaryOption.label,
            'wasteName' + primaryOption.value,
          ]
        })
        getCategoryChoices('waste').forEach((primaryOption: SelectOption) => {
          const children = getCategoryChoices('waste', primaryOption.value)
          if (children.length === 0) {
            const row = wasteCategoriesSheet.getRow(rowIndex)
            row.values = [primaryOption.label]
            rowIndex++
          } else {
            children.forEach((secondaryOption: SelectOption) => {
              const subChildren = getCategoryChoices('waste', secondaryOption.value)
              if (subChildren.length === 0) {
                const row = wasteCategoriesSheet.getRow(rowIndex)
                row.values = [primaryOption.label, secondaryOption.label]
                row.getCell(2).name = 'wasteName' + primaryOption.value
                rowIndex++
              } else {
                const secondaryCleanRow = cleanNameUtilsSheet.getRow(cleanNameRow++)
                secondaryCleanRow.values = [
                  // must be on col E and F
                  ...fillArray(secondaryCleanRow.values as any[], 4),
                  primaryOption.label + secondaryOption.label,
                  'wasteName' + secondaryOption.value,
                ]

                subChildren.forEach((tertiaryOption: SelectOption) => {
                  if (!wasteCategoriesExample) {
                    wasteCategoriesExample = [
                      primaryOption.label,
                      secondaryOption.label,
                      tertiaryOption.label,
                    ]
                  }

                  const row = wasteCategoriesSheet.getRow(rowIndex)
                  row.values = [primaryOption.label, secondaryOption.label, tertiaryOption.label]
                  row.getCell(2).name = 'wasteName' + primaryOption.value
                  row.getCell(3).name = 'wasteName' + secondaryOption.value
                  rowIndex++
                })
              }
            })
          }
        })
      }

      importSheet.columns = attributes.map((attribute: Attribute, index: number) => ({
        key: attribute.key + index,
      }))
      const countSeveralCol: Record<string, number> = {}

      attributes.forEach((attribute: Attribute, index: number) => {
        let label = t(attribute.label as any) + (attribute.required ? '*' : '')
        if (attribute.severalCol) {
          let index = (countSeveralCol[attribute.key] ?? 0) + 1
          label = `${label} #${index}`
          countSeveralCol[attribute.key] = index
        }
        const col = importSheet.getColumn(attribute.key + index)
        col.values = [label, ...Array.from({ length: 100 }, () => '')]

        if (attribute.type === 'slashSplit') {
        } else if (attribute.type === 'split') {
          col.eachCell({ includeEmpty: true }, function (cell, rowNumber: number) {
            if (rowNumber > 1) {
              cell.dataValidation = {
                type: 'custom',
                allowBlank: !attribute.required,
                formulae: [`NOT(ISERROR(SEARCH((":"),(${cell.$col$row}))))`],
              }
            }
          })
        } else if (attribute.type === 'date') {
          col.eachCell({ includeEmpty: true }, function (cell, rowNumber: number) {
            if (rowNumber > 1) {
              cell.dataValidation = {
                type: 'date',
                operator: 'greaterThanOrEqual',

                allowBlank: !attribute.required,
                formulae: [new Date(0)],
              }
            }
          })
        } else if (attribute.type === 'positiv') {
          col.eachCell({ includeEmpty: true }, function (cell, rowNumber: number) {
            if (rowNumber > 1) {
              cell.dataValidation = {
                type: 'decimal',
                operator: 'greaterThanOrEqual',
                allowBlank: !attribute.required,
                formulae: [0],
              }
            }
          })
        } else if (attribute.type === 'percent') {
          col.eachCell({ includeEmpty: true }, function (cell, rowNumber: number) {
            if (rowNumber > 1) {
              cell.dataValidation = {
                type: 'decimal',
                operator: 'between',
                allowBlank: !attribute.required,
                formulae: [0, 100],
              }
            }
          })
        } else if (!!attribute.type) {
          let formulae: (rowNumber: number) => string[]
          if (attribute.type === 'category') {
            if (attribute.key === 'primaryCategory') {
              const nbPrimary = getCategoryChoices('rae').length
              formulae = () => [`'${cleanNameName}'!$A$1:$A$${nbPrimary}`]
            } else if (attribute.key === 'secondaryCategory') {
              formulae = (rowNumber: number) => [
                // TODO get ride of @ in excel file and make it work all like Googlsheet
                // `'${secondaryUtilsName}'!A${rowNumber - 1}:Z${rowNumber - 1}`,
                `INDIRECT(VLOOKUP('${sheetName}'!$${primaryCategoryCol}${rowNumber},'${cleanNameName}'!$A$1:$B$999,2,))`,
              ]
            } else if (attribute.key === 'tertiaryCategory') {
              formulae = (rowNumber: number) => [
                // TODO get ride of @ in excel file and make it work all like Googlsheet
                // `'${tertiaryUtilsName}'!A${rowNumber - 1}:Z${rowNumber - 1}`,
                `INDIRECT(VLOOKUP('${sheetName}'!$${primaryCategoryCol}${rowNumber}&'${sheetName}'!$${secondaryCategoryCol}${rowNumber},'${cleanNameName}'!$A$1:$B$999,2,))`,
              ]
            }
          } else if (attribute.type === 'resourceCategory') {
            if (attribute.key === 'cerfaResource.primaryCategory') {
              const nbResourcePrimary = getCategoryChoices('resource').length
              formulae = () => [`'${cleanNameName}'!$C$1:$C$${nbResourcePrimary}`]
            } else if (attribute.key === 'cerfaResource.secondaryCategory') {
              formulae = (rowNumber: number) => [
                // TODO get ride of @ in excel file and make it work all like Googlsheet
                // `'${secondaryUtilsName}'!A${rowNumber - 1}:Z${rowNumber - 1}`,
                `INDIRECT(VLOOKUP('${sheetName}'!$${resourcePrimaryCategoryCol}${rowNumber},'${cleanNameName}'!$C$1:$D$999,2,))`,
              ]
            } else if (attribute.key === 'cerfaResource.tertiaryCategory') {
              formulae = (rowNumber: number) => [
                // TODO get ride of @ in excel file and make it work all like Googlsheet
                // `'${tertiaryUtilsName}'!A${rowNumber - 1}:Z${rowNumber - 1}`,
                `INDIRECT(VLOOKUP('${sheetName}'!$${resourcePrimaryCategoryCol}${rowNumber}&'${sheetName}'!$${resourceSecondaryCategoryCol}${rowNumber},'${cleanNameName}'!$C$1:$D$999,2,))`,
              ]
            }
          } else if (attribute.type === 'wasteCategory') {
            if (attribute.key === 'primaryCategory') {
              const nbWastePrimary = getCategoryChoices('waste').length
              formulae = () => [`'${cleanNameName}'!$E$1:$E$${nbWastePrimary}`]
            } else if (attribute.key === 'secondaryCategory') {
              formulae = (rowNumber: number) => [
                // TODO get ride of @ in excel file and make it work all like Googlsheet
                // `'${secondaryUtilsName}'!A${rowNumber - 1}:Z${rowNumber - 1}`,
                `INDIRECT(VLOOKUP('${sheetName}'!$${wastePrimaryCategoryCol}${rowNumber},'${cleanNameName}'!$E$1:$F$999,2,))`,
              ]
            } else if (attribute.key === 'tertiaryCategory') {
              formulae = (rowNumber: number) => [
                // TODO get ride of @ in excel file and make it work all like Googlsheet
                // `'${tertiaryUtilsName}'!A${rowNumber - 1}:Z${rowNumber - 1}`,
                `INDIRECT(VLOOKUP('${sheetName}'!$${wastePrimaryCategoryCol}${rowNumber}&'${sheetName}'!$${wasteSecondaryCategoryCol}${rowNumber},'${cleanNameName}'!$E$1:$F$999,2,))`,
              ]
            }
          } else {
            let values =
              attribute.type === 'boolean'
                ? [
                    t('materials:components.modalImport.boolean.true'),
                    t('materials:components.modalImport.boolean.false'),
                  ]
                : attribute.type === 'carbonModels'
                ? carbonModels.map((carbonModel) => carbonModel.name)
                : createOptionsFromEnum(attribute.type, attribute.enumPath as string).map(
                    (v: SelectOption) => v.label,
                  )
            formulae = () => [`"${values.join(',')}"`]
          }

          col.eachCell({ includeEmpty: true }, function (cell, rowNumber: number) {
            if (rowNumber > 1) {
              cell.dataValidation = {
                type: 'list',
                allowBlank: !attribute.required,
                formulae: formulae(rowNumber),
              }
            }
          })
        }

        if (attribute.instruction !== false) {
          let instruction = ''

          if (!attribute.multiLine && attribute.key === 'primaryCategory') {
            instruction = t(`materials:components.modalImport.instructions.primaryCategory`, {
              example: categoriesExample[0],
            })
          } else if (!attribute.multiLine && attribute.key === 'secondaryCategory') {
            instruction = t(`materials:components.modalImport.instructions.secondaryCategory`, {
              example: categoriesExample[1],
            })
          } else if (!attribute.multiLine && attribute.key === 'tertiaryCategory') {
            instruction = t(`materials:components.modalImport.instructions.tertiaryCategory`, {
              example: categoriesExample[2],
            })
          } else if (attribute.key === 'cerfaResource.primaryCategory') {
            instruction = t(
              `materials:components.modalImport.instructions.resourcePrimaryCategory`,
              {
                example: resourceCategoriesExample[0],
              },
            )
          } else if (attribute.key === 'cerfaResource.secondaryCategory') {
            instruction = t(
              `materials:components.modalImport.instructions.resourceSecondaryCategory`,
              {
                example: resourceCategoriesExample[1],
              },
            )
          } else if (attribute.key === 'cerfaResource.tertiaryCategory') {
            instruction = t(
              `materials:components.modalImport.instructions.resourceTertiaryCategory`,
              {
                example: resourceCategoriesExample[2],
              },
            )
          } else if (attribute.multiLine === 'cerfaWaste' && attribute.key === 'primaryCategory') {
            instruction = t(`materials:components.modalImport.instructions.wastePrimaryCategory`, {
              example: wasteCategoriesExample[0],
            })
          } else if (
            attribute.multiLine === 'cerfaWaste' &&
            attribute.key === 'secondaryCategory'
          ) {
            instruction = t(
              `materials:components.modalImport.instructions.wasteSecondaryCategory`,
              {
                example: wasteCategoriesExample[1],
              },
            )
          } else if (attribute.multiLine === 'cerfaWaste' && attribute.key === 'tertiaryCategory') {
            instruction = t(`materials:components.modalImport.instructions.wasteTertiaryCategory`, {
              example: wasteCategoriesExample[2],
            })
          } else if (
            attribute.multiLine === 'cerfaWaste' &&
            (attribute.key === 'primaryCategory' ||
              attribute.key === 'secondaryCategory' ||
              attribute.key === 'tertiaryCategory')
          ) {
            instruction = t(
              `materials:components.modalImport.instructions.${attribute.key}` as any,
              {
                example:
                  wasteCategoriesExample[
                    attribute.key === 'primaryCategory'
                      ? 0
                      : attribute.key === 'secondaryCategory'
                      ? 1
                      : 2
                  ],
              },
            )
          } else if (attribute.key === 'unit') {
            const choices = createOptionsFromEnum(Unit, 'materials:unitSymbol')
              .map((v: SelectOption) => v.label)
              .join(', ')
            const choiceFullnames = createOptionsFromEnum(Unit, 'materials:unit')
              .map((v: SelectOption) => v.label)
              .join(', ')
            instruction = t(`materials:components.modalImport.instructions.unit` as any, {
              choices,
              choiceFullnames,
            })
          } else if (attribute.instruction) {
            instruction = t(
              `materials:components.modalImport.instructions.${attribute.instruction}` as any,
            )
          } else if (attribute.type === 'date') {
            instruction = dayjs().localeData().longDateFormat('L')
          } else if (typeof attribute.type === 'object' || attribute.type === 'carbonModels') {
            const choices =
              attribute.type === 'carbonModels'
                ? carbonModels.map((carbonModel) => carbonModel.name)
                : createOptionsFromEnum(attribute.type, attribute.enumPath as string)
                    .map((v: SelectOption) => v.label)
                    .join(', ')
            instruction = t(`materials:components.modalImport.instructions.enum`, {
              choices,
            })
          } else if (attribute.type) {
            instruction = t(
              `materials:components.modalImport.instructions.${attribute.type}` as any,
            )
          }

          const type = !attribute.type
            ? 'text'
            : typeof attribute.type === 'object'
            ? 'enum'
            : attribute.type
          let instructionRow = instructionsSheet.getRow(instructionRowIndex++)
          instructionRow.values = [
            `${label} (${t(
              `materials:components.modalImport.instructions.type.${type}` as any,
            )}) :`,
          ]
          const instructionLines = instruction.split('\n')
          instructionLines.forEach((instructionLine) => {
            instructionRow = instructionsSheet.getRow(instructionRowIndex++)
            instructionRow.values = [instructionLine]
            instructionRow.getCell(1).font = { bold: false }
          })
          instructionRowIndex++
        }
      })

      // resize columns
      ;[categoriesSheet, importSheet, instructionsSheet].forEach((sheet) => {
        sheet.columns.forEach(function (column) {
          const lengths = column.values?.map((v) => v?.toString().length ?? 0) ?? []
          const maxLength = Math.max(...lengths.filter((v) => typeof v === 'number'))
          column.width = Math.max(maxLength, 10)
        })
      })
      importSheet.getColumn(1).width = Math.max(25)
      importSheet.getColumn(2).width = Math.max(25)
      importSheet.getColumn(3).width = Math.max(25)

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer])
      await FileUtils.downloadFile(t('materials:components.modalImport.filename') + '.xlsx', blob)
    } catch (err: any) {
      show(err)
    }

    setLoading(false)
  }, [carbonModels, show, attributes, t, computedShowCerfa])

  return (
    <>
      {loading && <LoaderOverlay />}
      <ModalForm
        {...formProps}
        value={value}
        setValue={setValue}
        maxWidth="xl"
        items={[
          {
            type: ItemType.link,
            key: 'format',
            props: {
              children: t('materials:components.modalImport.downloadFormat'),
              onClick: downloadFormat,
            },
          },
          {
            type: ItemType.select,
            key: 'fileType',
            required: true,
            grid: { xs: 4, lg: 2 },
            props: {
              items: [
                { value: 'rae', label: t('materials:components.modalImport.rae') },
                { value: 'cstb', label: t('materials:components.modalImport.cstb') },
              ],
              label: t('materials:components.modalImport.fileType'),
            },
          },
          {
            type: ItemType.file,
            key: 'file',
            required: true,
            rules: [
              () =>
                value.materials.length === 0
                  ? t('materials:components.modalImport.noMaterials')
                  : value.materials.some((m) => m.hasError)
                  ? t('materials:components.modalImport.materialHasError')
                  : '',
            ],
            grid: { xs: 8, lg: 4 },
            onChange: loadData,
            props: {
              label: t('materials:components.modalImport.file'),
              placeholder: t('materials:components.modalImport.filePlaceholder'),
              accept: '.csv,.xlsx',
            },
          },
          {
            type: ItemType.directory,
            key: 'imagesFolder',
            grid: { xs: 12, lg: 6 },
            onChange: loadDirectory,
            props: {
              maxFileSize: constants.ui.maxSize.image,
              label: t('materials:components.modalImport.imagesFolder'),
              placeholder: t('materials:components.modalImport.imagesFolderPlaceholder'),
            },
          },
          {
            type: ItemType.custom,
            key: 'materials',
            grid: { xs: 12 },
            custom: (
              <Table
                onValueClick={(_: any, index: number) => {
                  setIndexOpen(index)
                }}
                columns={[
                  {
                    type: ItemType.file,
                    label: t('materials:components.modalImport.mainImageFileFile'),
                    key: 'mainImageFileFile',
                    onChange: (colIndex: number, file: File) => {
                      setValue((val: any) => ({
                        ...val,
                        materials: value.materials.map((material: any, mapIndex: number) => {
                          const updated = { ...material, mainImageFileFile: file }
                          updated.hasError = hasError(updated)
                          return mapIndex === colIndex ? updated : material
                        }),
                      }))
                    },
                    props: {
                      maxFileSize: constants.ui.maxSize.image,
                      accept: 'image/*',
                    },
                  },
                  {
                    label: t('materials:attributes.name'),
                    key: 'name',
                  },
                  {
                    label: t('materials:components.modalImport.hasError'),
                    key: 'hasError',

                    formatValue: (hasError?: boolean) => {
                      return hasError
                        ? t('materials:components.modalImport.invalid')
                        : t('materials:components.modalImport.valid')
                    },
                  },
                  (material: any) => ({
                    label: '',
                    key: 'delete',
                    type: ItemType.custom,
                    custom: (
                      <IconButton
                        onClick={(e: any) => {
                          e.stopPropagation()

                          setValue((val: any) => ({
                            ...val,
                            materials: value.materials.filter(
                              (stateMaterial: any) => material._id !== stateMaterial._id,
                            ),
                          }))
                        }}>
                        <DeleteIcon color="primary" />
                      </IconButton>
                    ),
                  }),
                ]}
                values={{
                  data: value.materials,
                  total: value.materials.length,
                  count: value.materials.length,
                }}
              />
            ),
          },
        ]}
      />

      {indexOpen >= 0 && indexOpen < value.materials.length && (
        <ModalFormMaterial
          showCerfa={computedShowCerfa}
          useImperials={useImperials}
          material={value.materials[indexOpen]}
          onClose={() => setIndexOpen(-1)}
          onSubmit={(updated: any) => {
            setValue((val: any) => ({
              ...val,
              materials: value.materials.map((material: any, mapIndex: number) => {
                updated.hasError = hasError(updated)
                return mapIndex === indexOpen ? updated : material
              }),
            }))
          }}
          title={t(`materials:actions.update${type}.label`)}
        />
      )}
    </>
  )
}
export default ModalImport
